@import url("https://fonts.googleapis.com/css2?family=Nanum+Gothic:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Train+One&display=swap");

:root {
  --base-color: rgb(156, 10, 240);
  --base-color-lighter-1: rgb(156, 50, 255);
  --base-color-lighter-2: rgb(156, 100, 255);
  --yellow: #ffe863;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html {
  scroll-behavior: smooth;
}
body {
  font-family: "Nanum Gothic", sans-serif;
}
img {
  width: 100%;
  height: auto;
}
a {
  text-decoration: none;
}
